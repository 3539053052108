import React from "react"
import Layout from "../../components/global/layout"
import Logo from "../../images/brand/logo.svg"

import { CardCarousel } from "../../components/block/CardCarousel"
import { urls } from "../../data/urls"

const data = {
  title: "Different is Normal",
  cards: [
    {
      type: "video",
      url: "https://player.vimeo.com/video/461740656?dnt=1",
    },
    {
      type: "text",
      to: null,
      withSkip: true,
      text: "",
      textColor: "wine",
      backgroundColor: "vanilla",
      title: "Here's the thing, there's no such thing as 'normal'",
    },
    {
      type: "text",
      to: null,
      withSkip: true,
      text: "",
      textColor: "wine",
      backgroundColor: "peach",
      title:
        "Like every other part of our bodies, our vulvas are unique and the differences are something to celebrate",
    },
    {
      type: "text",
      to: null,
      withSkip: true,
      text: "",
      textColor: "wine",
      backgroundColor: "apricot",
      title:
        "Still, some of us worry about being different because we don't see enough diversity around us.",
    },
    {
      type: "text",
      to: urls.INTRO_END,
      title: null,
      withSkip: false,
      textColor: "white",
      backgroundColor: "rose",
      text:
        "Which is why Brook and Canesten have joined together – to help you get to know your vulva, to love your difference and to see that different is normal.",
    },
  ],
}

function introCards() {
  return (
    <Layout logoOverride={Logo} hideHamburger={true}>
      <CardCarousel cards={data.cards} />
    </Layout>
  )
}

export default introCards
